<template>
  <div class="property-wrapper">
    <template v-if="controls.length == 1">
      <ControlBaseProperties v-model="control" />
      <div class="form-group">
        <label for="editor-format">{{ $t("format") }}</label>
        <input
          type="text"
          class="form-control"
          v-model="control.format"
          id="editor-format"
          :placeholder="`${$tc('eg', 2)} %d`"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import ControlBaseProperties from "./control-base-properties";
import BaseControl from "./base-control.vue";

export default {
  name: "IconizedDisplayValue",
  components: { ControlBaseProperties },
  extends: BaseControl,
  data() {
    return {
      control: {}
    };
  },
  watch: {
    control: {
      deep: true,
      handler(val) {
        this.updateControl({
          id: val.id,
          control: {
            ...val
          }
        });
      }
    },
    controls(val) {
      this.control = val[0];
    }
  },
  methods: {
    ...mapActions("synoptic", ["updateControl"])
  },
  mounted() {
    this.control = this.controls[0];
  }
};
</script>
